import { render, staticRenderFns } from "./SimpleKeyboard.vue?vue&type=template&id=cff5b93c&scoped=true&"
import script from "./SimpleKeyboard.vue?vue&type=script&lang=js&"
export * from "./SimpleKeyboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../TelemetryHubApp/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cff5b93c",
  null
  
)

export default component.exports