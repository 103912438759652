import { render, staticRenderFns } from "./TimeBadge.vue?vue&type=template&id=2025da16&scoped=true&"
import script from "./TimeBadge.vue?vue&type=script&lang=js&"
export * from "./TimeBadge.vue?vue&type=script&lang=js&"
import style0 from "./TimeBadge.vue?vue&type=style&index=0&id=2025da16&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../TelemetryHubApp/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2025da16",
  null
  
)

export default component.exports