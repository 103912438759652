export default class CookieManager {
    setCookie(name, value, hours) {
        let expires = "";
        if(hours === 0) return
        if (hours) {
            let date = new Date();
            date.setTime(date.getTime() + (hours * 60 * 60 * 1000)); // Calcula a data de expiração em milissegundos
            expires = "; expires=" + date.toUTCString(); // Define a string de expiração
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/"; // Define o cookie com a data de expiração
    }

    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    eraseCookie(name) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }

    setDefaultExpireValue() {
        localStorage.setItem('cookie-expire-timeout', '0');
        return 0;
    }

    getExpireTime() {
        if (localStorage.getItem('cookie-expire-timeout')) {
            try {
                return parseInt(localStorage.getItem('cookie-expire-timeout'));
            } catch {
                return this.setDefaultExpireValue();
            }
        } else {
            return this.setDefaultExpireValue();
        }
    }

    setExpireTime(value) {
        if (isNaN(value)) {
            throw new Error('Invalid cookie time');
        }
        localStorage.setItem('cookie-expire-timeout', value.toString());
        const hours = value / 60;
        this.updateAllCookiesExpiration(hours);
        return parseInt(value);
    }

    updateAllCookiesExpiration(hours) {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf('=');
            let name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();
            let value = eqPos > -1 ? cookie.substring(eqPos + 1).trim() : '';
            if(name.length == 0) return
            else if(hours === 0) this.eraseCookie(name);
            else this.setCookie(name, value, hours);
        }
    }
}
