class ThemeManager {

    constructor(){
        this.images = [
            {id: 'default', selected: true, url: 'https://i.imgur.com/7yWvRIE.jpeg'},
            {id: 'blackhole', selected: false, url: 'https://i.imgur.com/oSQg00g.jpeg'},
            {id: 'sifi', selected: false, url: 'https://img.freepik.com/fotos-premium/fundo-abstrato-da-tecnologia_731930-4265.jpg?w=1380'},
            {id: 'ship', selected: false, url: 'https://img.freepik.com/fotos-premium/uma-enorme-nave-espacial-na-galaxia-filme-cinematografico-cinematografico-batalha-espacial-ainda-intensa-entre_146482-11547.jpg?w=1480'},
            {id: 'mountain', selected: false, url: 'https://i.imgur.com/zPRt6Uj.jpeg'},
            {id: 'matrix', selected: false, url: 'https://i.imgur.com/byRofdl.jpeg'},
        ]

        this.colors = [
            {id: "default", selected: true, color: '#1e2b56'},
            {id: "light", selected: false, color: '#76a9ed'},
            {id: "alternative", selected: false, color: '#662549'},
            {id: "terminal", selected: false, color: '#2FF557'},
        ]

        this.update()
    }

    getColors(){
        return this.colors
    }

    getImages(){
        return this.images
    }

    update() {
        var colorTheme = this.getThemeColor() || 'default';
        var imageTheme = this.getThemeImage() || 'default';

        this.images = this.images.map(img => {
            img.selected = img.id === imageTheme;
            return img
        })

        this.colors = this.colors.map(color => {
            color.selected = color.id === colorTheme;
            return color
        })

        this.updateThemeColor()
        this.updateThemeImage()


    }

    updateThemeColor(){
        document.querySelector('html').setAttribute("theme", this.getThemeColor())
    }

    updateThemeImage(){
        const body = document.querySelector('body')
        const image = this.images.find(img => img.selected)
        body.style.setProperty('--background-image', `url(${image.url})`)
    }


    setDefaultValue() {
        localStorage.setItem('theme', '');
        return '';
    }

    getThemeColor() {
        if (localStorage.getItem('theme')) {
            try {
                return localStorage.getItem('theme');
            } catch {
                return this.setDefaultValue();
            }
        } else {
            return this.setDefaultValue();
        }
    }

    setThemeColor(color = '') {
        if (color === 'default') color = '';
        localStorage.setItem('theme', color);
        this.update()
        return this.colors
    }

    setDefaultImageValue() {
        localStorage.setItem('bgImg', '');
        return '';
    }

    getThemeImage() {
        if (localStorage.getItem('bgImg')) {
            try {
                return localStorage.getItem('bgImg');
            } catch {
                return this.setDefaultImageValue();
            }
        } else {
            return this.setDefaultImageValue();
        }
    }

    setThemeImage(img = '') {
        if (img === 'default') img = '';
        localStorage.setItem('bgImg', img);
        this.update()
        return this.images
    }
}

const manager = new ThemeManager;

export default manager;
