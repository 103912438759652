export default {
    props: {
        failedConnectionWithAxios: {
            required: false,
            default: false,
            type: Boolean
        }
    },

    computed: {
        axiosFail(){
            return this.failedConnectionWithAxios
        }
    }
}