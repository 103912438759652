function setDefaultValue(){
    localStorage.setItem('numberOfRowsPerPage', '15')
    return 15
}

export function getNumberOfRowsPerPage() {
    if(localStorage.getItem('numberOfRowsPerPage')) {
        try{
            return parseInt(localStorage.getItem('numberOfRowsPerPage'))
        }catch{
            return setDefaultValue()
        }
    }else {
        return setDefaultValue()
    }
}

export function setNumberOfRowsPerPage(value) {
    localStorage.setItem('numberOfRowsPerPage', value)
    return parseInt(value)
}