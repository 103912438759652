export default {
    data(){
        return{
            editingGroupName: false,
            isOpen: true,
            measurers : [], // Armazena os medidores do grupo para poder realizar as alterações em seu tamanho
            hadChanges: false // Seu estado muda quando há mudanças no gridlayout ou quando as mudanças são sincronizadas
        }
    },
    methods:{
        gridChange(measurerList){ //mostra o botão de atualizar
            this.measurers = measurerList
            this.hadChanges = true
        },
        updateMeasurersPositions(){
            this.measurers.forEach(async (measurer) =>{
                await this.measurerService.search(measurer.id).then(element => {
                    measurer.group = element.group
                    measurer.layer = element.layer
                })
                this.measurerService.updatePosition(measurer.id, measurer.positionX, measurer.positionY, measurer.width, measurer.height).then(el=>{
                    console.log(el)
                }).catch(err=>{
                    console.log(err)
                })
            })
        },
        toggleCollapase(){
            this.isOpen = !this.isOpen
        },

        updateGridChanges(){ // esconde o botão de atualizar
            this.hadChanges = false
            this.updateMeasurersPositions()
        },
        editGroupName(){
            this.editingGroupName = true
            this.$refs.nameInput.classList.add("edit")
        },
        leftInput(e){
            const newName = e.srcElement.value
            this.editingGroupName = false
            this.$refs.nameInput.classList.remove("edit")

            if(newName != this.groupName){
                this.data.name = newName
                this.service.search(this.data.id).then(response => {
                    response.name = newName
                    this.service.update(response)
                })
            }

        }
    },
    computed:{
        isOpenTitle(){
            return this.isOpen ? this.$i18n.t('close') : this.$i18n.t('open')
        },
        groupCollapseId(){
            return this.data ? `${this.data.name}_${this.data.id}` : 'group-collapse'
        },
        groupName(){
            return this.data ? this.data.name : "Nome do grupo"
        },
        groupWidth(){
            return {"--width": this.zoomLevel <= 50 ? `${ document.getElementById("dashboardArea").offsetWidth /2}px` : "auto"}
        }
    },
    props:{
        data:{
            type: Object,
            required:false
        },
        zoomLevel: {
            type: Number
        },
        isFullscreen:{
            required:false,
            default: false,
            type: Boolean
        }
    },
    mounted(){
        this.service = new this.$groupService();
        this.measurerService = new this.$measurerService();
        document.addEventListener('keydown', (e)=>{
            if(this.editingGroupName && e.key === "Enter") this.leftInput({
                srcElement: this.$refs.nameInput
            })
        })
    }
}