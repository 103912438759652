function setDefaultValue(){
    localStorage.setItem('enabledToasts', '1')
    return 1
}

function getIsToastsEnabled() {
    if(localStorage.getItem('enabledToasts')) {
        try{
            return parseInt(localStorage.getItem('enabledToasts'))
        }catch{
            return setDefaultValue()
        }
    }else {
        return setDefaultValue()
    }
}

function setIsToastEnabled(value) {
    localStorage.setItem('enabledToasts', value)
    return parseInt(value)
}

export default { getIsToastsEnabled, setIsToastEnabled }