export default {

    data() {
        return {
            filter: '',
            selected: [],
        }
    },

    props: {
        source: {
            type: Array,
            default: () => [],
            required: true,
        },
    },


    computed: {
        computedItems() {
            return this.source.filter(el => {
                return el.name.includes(this.filter)
            });
        },

        isAllChecked() {
            return this.computedItems.length == this.selected.length
        }
    },

    watch: {
        selected: {
            deep: true, // Required to detect changes within the array
            handler(newVal, oldVal) {
                console.log(newVal);
                this.$emit('itemsChanged', newVal); // Emit the updated array
            },
        },
    },
}
